"use client"
import Image from "next/image";
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { Profile } from '@/interfaces/profile';
import { basicPromptTestToShepherd } from '@/services/shepherd-ai.service';

import CircularProgress from '@mui/material/CircularProgress';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
interface Props {
	profileData: Profile | undefined
}

export default function CovAssistant({ profileData }: Props) {
	const [showAssistanceToolTip, setShowAssistanceToolTip] = useState<boolean>(false);
	return (
		<div className='absolute max-w-[140px]'>
			{
				showAssistanceToolTip && <MultiModalEditor profileData={profileData} />
			}

			<section className='z-40 rounded-full fixed bottom-2 md:bottom-8 right-2 md_right-12 hover:-translate-y-1 border-2 border-indigo-400 transition duration-150 ease-in-out hover:border-white'>
				<button className='p-0 m-0 flex items-center' onClick={() => setShowAssistanceToolTip(!showAssistanceToolTip)}>
					<Image
						src="/logo/maskable-icon-x192.png"
						alt="Covalence logo"
						width={64}
						height={64}
						className='rounded-full'
						priority
					/>
				</button>
			</section>
		</div>

	)
}

const ChatHeader = ({ profileData }: any) => {
	if (!profileData) return null;

	const startingMessage = `Hello! How can I help you today ${profileData.firstName}?`;

	return (
		<div>
			{startingMessage}
		</div>
	);
};

function MultiModalEditor({ profileData }: Props) {
	const [latestMessage, setLatestMessage] = useState<string | undefined>(undefined);
	const [loadingResponseFromShepherd, setLoadingMessageFromShepherd] = useState<Boolean>(false);
	const [messages, setMessages] = useState<{ role: string; content: string }[]>([]);

	const handleEnterCovAssistance = async (e: any) => {
		if (e.key === 'Enter') {
			await handleUserPromptToShepherd();
		}
	};

	const onChangeHandler = (e: any) => {
		setLatestMessage(e.target.value);
	}

	const handleUserPromptToShepherd = async () => {
		setLoadingMessageFromShepherd(true);
		if (typeof latestMessage !== 'undefined') {
			const newUserMessage = {
				role: 'user',
				content: `${latestMessage}\n`
			};
			setMessages([...messages, newUserMessage]);
			const result = await basicPromptTestToShepherd(latestMessage);
			setMessages(result.data.agent_answer);
			const newMessage = {
				role: 'assistant',
				content: `${result.data.agent_answer}`
			};
			setMessages([...messages, newMessage]);
			setLoadingMessageFromShepherd(false);
		}
	};

	const handleClearChat = () => {
		setMessages([]);
		setLatestMessage(undefined);
		setMessages([]);
		setLoadingMessageFromShepherd(false);
	};

	return (
		<div className='bg-slate-900 rounded-md z-40 p-2 mx-2 fixed bottom-28 right-10 min-h-[320px] max-w-[380px] grid grid-cols-1 content-between'>
			<div className='grid grid-cols-1 gap-2 p-2'>
				<ChatHeader profileData={profileData} />
				{typeof messages !== 'undefined' && (
					<ul className='h-[480px] overflow-auto border p-2'>
						{messages.map((message, index) => (
							<li key={index}>
								<span className={message.role === 'user' ? "text-cyan-200" : "text-orange-400"}>{message.role === 'user' ? "Question" : "Answer"} {message.content}</span>
							</li>
						))}
					</ul>
				)}
			</div>

			{loadingResponseFromShepherd && <CircularProgress />}

			<div className='flex items-center justify-between space-x-2 m-2'>
				<input
					name="cov-assistant-input-system"
					id="cov-assistant-input-system"
					onKeyDown={(e) => handleEnterCovAssistance(e)}
					className='defaultInputStyling h-[40px]'
					onChange={onChangeHandler}
				/>
				<button onClick={() => handleUserPromptToShepherd()} role='button'>
					<SendIcon className='hover:text-indigo-400' />
				</button>
			</div>

			<button onClick={() => handleClearChat()} role='button' className="defaultButtonStyling">
				Clear
			</button>
		</div>
	);
}