'use client'
import { usePathname } from 'next/navigation'
import Link from 'next/link'

import HomeIcon from '@mui/icons-material/Home';
import BookIcon from '@mui/icons-material/Book';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import GroupsIcon from '@mui/icons-material/Groups';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import ForumIcon from '@mui/icons-material/Forum';
import PublicIcon from '@mui/icons-material/Public';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import RateReviewIcon from '@mui/icons-material/RateReview';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import TerminalIcon from '@mui/icons-material/Terminal';
import { useMemo, useState } from 'react';

export default function MainMenu() {
	const pathname = usePathname();
	const [isOpen, setIsOpen] = useState<boolean>(true);

	interface Route {
		link: string;
		text: string;
	}

	const routes: Route[] = [
		{
			link: '/home',
			text: 'Home'
		},
		{
			link: '/profile',
			text: 'Profile'
		},
		{
			link: '/ole',
			text: 'OLE'
		},
		{
			link: '/ope',
			text: 'OPE'
		},
		{
			link: '/ore',
			text: 'ORE'
		},
		{
			link: '/ode',
			text: 'ODE'
		},
		{
			link: '/inbox',
			text: 'Inbox'
		},
		{
			link: '/teams',
			text: 'Teams'
		},
		{
			link: '/community',
			text: 'OSC'
		},

		{
			link: '/footsteps',
			text: 'Footsteps'
		},

	];

	const isMenuVisible = useMemo(() => {
		if (pathname === '/signup' || pathname === '/login' || pathname === '/') {
			return false;
		}
		return true;
	}, [pathname]);

	const getIconComponentByPathname = (pathname: string) => {
		switch (pathname) {
			case '/home':
				return <HomeIcon />;
			case '/ole':
				return <BookIcon />;
			case '/uploadResearchPaper':
				return <UploadFileIcon />;
			case '/ore':
				return <RateReviewIcon />;
			case '/ode':
				return <TerminalIcon />;
			case '/ope':
				return <UploadFileIcon />;
			case '/inbox':
				return <ForumIcon />;
			case '/footsteps':
				return <AltRouteIcon />;
			case '/teams':
				return <GroupsIcon />;
			case '/community':
				return <PublicIcon />;
			case '/profile':
				return <AccountBoxIcon />;
			default:
				return <HomeIcon />; // Return null for routes without associated components
		}
	};

	return (<div className={isMenuVisible ? 'block bg-indigo-500 min-h-screen' : 'hidden'}>
		<nav
			className='grid grid-cols-1 gap-2 p-1 min-w-full'
		>
			{
				routes.map((route: Route, i: number) => {
					return (
						<Link
							key={i}
							className={`link ${pathname === route.link
								? 'miniButtonActiveStyling justify-start'
								: 'miniButtonStyling justify-start'
								}`}
							href={route.link}
						>
							{getIconComponentByPathname(route.link)}
							<span className={isOpen ? 'flex px-8 justify-start ' : 'hidden'}>
								{route.text}
							</span>
						</Link>
					);
				})
			}
			<button onClick={() => setIsOpen(!isOpen)} className='hidden md:block rounded-full bg-indigo-600 px-1 hover:bg-indigo-800 ease-in-out duration-150 transition'>
				{isOpen ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
			</button>
		</nav>
	</div>
	)
}