'use client'
import Image from "next/image";
import { useMemo, useState } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { logout } from '@/services/auth.service';

import { Profile } from '@/interfaces/profile';
import MobileMenu from './mobile-menu';
import MenuIcon from '@mui/icons-material/Menu';

interface Props {
	_profileData: Profile | undefined
}

export default function TopNavigation({ _profileData }: Props) {
	const [showMenu, setShowMenu] = useState<Boolean>(false);

	const pathname = usePathname();
	const isMenuVisible = useMemo(() => {
		if (pathname === '/signup' || pathname === '/login' || pathname === '/') {
			return false;
		}
		return true;
	}, [pathname]);

	async function Logout() {
		await logout();
	}

	return (
		<div className='grid grid-cols-4 gap-2 relative items-center min-w-full justify-between bg-gradient-to-t p-1 from-indigo-500 to-indigo-300 py'>
			<Link href={typeof _profileData !== undefined ? "/home" : "/"} className='flex items-center justify-self-start'>
				<div className='relative mr-2'>
					<Image
						src="/logo/maskable-icon-x192.png"
						alt="Covalence logo"
						width={48}
						height={48}
						className='rounded-md'
						priority
					/>
				</div>
				<span className='font-medium text-xl hidden md:flex'>
					Covalence
				</span>
			</Link>
			{
				typeof _profileData == undefined && <div className='grid grid-cols-2 gap-2 col-span-3'>
					<Link href="/signup" className='defaultButtonApproveStyling' >
						Try for free, now!
					</Link>
					<Link href="/profile" className='defaultButtonStyling' >
						Continue your best work
					</Link>
				</div>
			}
			<button role="button" aria-label="Access application top menu controls button" className='md:hidden flex items-center justify-center bg-indigo-600 h-8 rounded-md col-start-4' onClick={() => setShowMenu(!showMenu)} >
				<MenuIcon />
			</button>
			{
				showMenu &&
				<MobileMenu setShowMenu={setShowMenu} />
			}
		</div>
	)
}